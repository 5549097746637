<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-card-title class="elevation-0">
          <span class="itemHeading">TEAM MEMBERS</span>
          <v-spacer></v-spacer>
          <v-flex xs6 sm6 md3 pt-5 align-self-center>
            <v-text-field
              label="Search Employee"
              outlined
              dense
              class="rounded-xl"
              v-model="searchKeyword"
            ></v-text-field>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start pa-5 v-if="teamMembers.length > 0">
          <v-flex xs12>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead style="background-color: #005f32">
                  <tr>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Name
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Email
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Employee Id
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      View Profile
                    </th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in teamMembers"
                    :key="i"
                  >
                    <td>
                      <span v-if="item.name" style="font-family: kumbhMedium">{{
                        item.name
                      }}</span>
                    </td>

                    <td>
                      <span
                        v-if="item.email"
                        style="font-family: kumbhMedium"
                        >{{ item.email }}</span
                      >
                    </td>

                    <td>
                      <span
                        v-if="item.employeeId"
                        style="font-family: kumbhMedium"
                        >{{ item.employeeId }}</span
                      >
                    </td>
                    <td>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pa-2>
                            <router-link :to="'/Attendance/teamMemberDetails?id=' + item._id">
                          <v-icon title="View" color="#005f32">mdi-eye</v-icon></router-link>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Data found!</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
      <script>
import axios from "axios";
export default {
  data: (vm) => ({
    showsnackbar: false,
    ServerError: false,
    msg: null,
    pages: 0,
    dialog: false,
    pageCount: 1,
    appLoading: false,
    currentPage: 1,
    g: false,
    searchKeyword: "",
    userId: localStorage.getItem("userid"),
    totalData: 0,
    addcat: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    teamMembers: [],
    data: [],
    name: null,
    assetId: null,
    dateFilter: new Date().toISOString().substr(0, 10),
    date: new Date().toISOString().substr(0, 7),
    dateFormatted: vm.formatDateMonth(new Date().toISOString().substr(0, 7)),
    menu1: false,
    menu2: false,
    dat: "",
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDateMonth(this.date);
    },
    // userId() {
    //   return this.$store.state.userData._id;
    // },
  },
  mounted() {
    if (this.$store.state.currentPage) {
      this.currentPage = this.$store.state.currentPage;
      this.getData();
    }
    else  this.getData();
  },
  watch: {
    searchKeyword() {
      this.currentPage = 1;
      this.getData();
    },
    currentPage() {
      this.getData();
      this.$store.commit("changeCurrentPageTeam", this.currentPage);
    },
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/team/members/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 20,
          empId: this.userId,
          searchKeyword: this.searchKeyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.teamMembers = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatDateMonth(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}-${year}`;
    },
  },
};
</script>
    <style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}
</style>
      